<template>
  <div class="category">
    <van-index-bar :index-list="nav" :sticky="false" ref="indexBar">
      <div
        v-for="cate in cates"
        :key="cate.cid"
        :class="cate.cid < 1000 ? 'title' : 'item'"
      >
        <van-index-anchor
          v-if="cate.level === 1"
          :index="cate.name"
          :data-cid="cate.cid"
        />
        <router-link
          v-if="cate.level === 2"
          :to="{ name: 'Catesub', params: { id: cate.cid } }"
          :data-cid="cate.cid"
        >
          <img :src="cate.pic" />
          <span>{{ cate.name }}</span>
        </router-link>
      </div>
    </van-index-bar>
  </div>
</template>


<script>
export default {
  data() {
    return {
      token: localStorage.getItem("token"),
      cates: null,
      nav: [],
      active: "",
    };
  },
  created() {
    this.active = this.$route.params.name;
    this.getCates();
  },
  mounted() {
    // setTimeout(() => {
    //   this.$refs.indexBar.scrollTo("女士精品");
    // }, 1000);
  },
  methods: {
    getCates() {
      this.$ajax.post("/market/onsale/category/", {}).then((res) => {
        this.cates = res.cats;
        let catesSort = [];
        for (let j = 0; j < this.cates.length; j++) {
          let cate = this.cates[j];
          if (cate.level === 1) {
            catesSort.push(cate);
            this.nav.push(cate.name); //这个是给导航用的
            for (let i = 0; i < this.cates.length; i++) {
              if (this.cates[i].parent_cid === cate.cid) {
                catesSort.push(this.cates[i]);
              }
            }
          }
        }
        this.cates = catesSort;
        //滚动到
        if (this.active) {
          setTimeout(() => {
            this.$refs.indexBar.scrollTo(this.active);
          }, 10);
        }
      });
    },
  },
};
</script>
